.card-video{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #0005;
}
.card-video .close-button {
    position: absolute;
    top: 18%;
    left: 76%;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    font-weight: 800;
}
.card-video .close-vid-bg{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: rgba(255, 255, 255, 0.16);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(19.4px);
        -webkit-backdrop-filter: blur(19.4px);
        border: 1px solid rgba(255, 255, 255, 0.21);
}
.card-video video{
    width: 50%;
    position: absolute;
    z-index: 2;
    max-height: 80%;
    background-color: #0007;
    /* border-radius: 30px; */
}

@media screen and (max-width:950px) and (min-width:750px) {
    .card-video{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background-color: #0005;
    }
    .card-video .close-button {
        position: absolute;
        top: 18%;
        left: 76%;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 1.2vw;
        font-weight: 800;
    }
    .card-video .close-vid-bg{
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background: rgba(255, 255, 255, 0.16);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(19.4px);
            -webkit-backdrop-filter: blur(19.4px);
            border: 1px solid rgba(255, 255, 255, 0.21);
    }
    .card-video video{
        width: 90%;
        position: absolute;
        z-index: 2;
        max-height: 80%;
        background-color: #0007;
        /* border-radius: 30px; */
    }
}


@media screen and  (max-width:750px) {
    .card-video{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background-color: #0005;
    }
    .card-video .close-button {
        position: absolute;
        top: 18%;
        left: 76%;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 1.2vw;
        font-weight: 800;
    }
    .card-video .close-vid-bg{
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background: rgba(255, 255, 255, 0.16);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(19.4px);
-webkit-backdrop-filter: blur(19.4px);
border: 1px solid rgba(255, 255, 255, 0.21);
    }
    .card-video video{
        width: 90%;
        position: absolute;
        z-index: 2;
        max-height: 80%;
        background-color: #0007;
        /* border-radius: 30px; */
    }
}