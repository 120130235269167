.sticky-button {
    border-radius: 16px;
    /* background-color: rgb(10 10 13 / 20%); */
    background-color: #0400ff;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: none;
    padding: 00px 20px;
    color: #fff;
    font-weight: bold;
    /* position: fixed;
    bottom: 15px;
    right: 4.8vw; */
    z-index: 999;
    border-radius: 30px;
    font-family: 'proxima-nova';
    font-weight: 900;
    cursor: pointer;
    text-transform: uppercase;
}
.btn{
        display: flex;
        height: 40px;
        position: fixed;
        bottom: 15px;
        right: 1%;
        z-index: 99;
        gap: 10px;
}

.btn img {
    background-color: #FF9900;
    fill: #fff;
    border: none;
    /* padding: 8px 11px; */
    width: 40px;
    font-size: 20px;
    height: 40px;
    color: #fff;
    font-weight: bold;
    /* position: fixed;
    bottom: 20px;
    right: 1vw; */
    z-index: 999;
    object-fit: none;
    border-radius: 75%;
    cursor: pointer
}
.popup {
    width: 100%;
    height: 100vh;
    background: transparent;
}
.close-popup{
width: 100%;
height: 100vh;
position: absolute;
top: 0;
left: 0;
}
.popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 35px 35px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-family: 'Poppins', sans-serif;
    width: 35%;
    height:initial;
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-size: 1.3vw;
    gap: 20px;
    border-right: 5px solid  #FF9900;
    border-bottom: 5px solid  #FF9900;
}
.popup-content h1{
    text-align: start;
    background-color: transparent;
    position: relative;
    top: -2vh;
    font-size: 20px;
}.popup-content h1::after{
    content: "";
    position: absolute;
    bottom: -15%;
    left: 0;
    width: 50%;
    height: 5px;
    background-color: #FF9900;
}

.popup-content h2{
    text-align: start;
    text-transform: uppercase;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
}
.close-icon{
    position: absolute;
    top: 20%;
    font-size: 0;
    right: 1%;
    top: 2%;
    font-weight: 900;
    background: transparent;
    color: white;
    cursor: pointer;
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 1, 1, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width:768px) and (max-width:1027px){
    .sticky-button {
        border-radius: 16px;
        /* background-color: rgb(10 10 13 / 20%); */
        background-color: #0400ff;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border: none;
        padding: 00px 20px;
        color: #fff;
        font-weight: bold;
        /* position: fixed;
        bottom: 15px;
        right: 4.8vw; */
        z-index: 999;
        border-radius: 30px;
        font-family: 'proxima-nova';
        font-weight: 900;
        cursor: pointer;
        text-transform: uppercase;
    }
    .btn{
        display: flex;
        height: 40px;
        position: fixed;
        bottom: 15px;
            right: 1%;
            z-index: 9999;
            gap: 10px;
    }
    
    .btn img {
        background-color: #FF9900;
        fill: #fff;
        border: none;
        /* padding: 8px 11px; */
        width: 40px;
        font-size: 20px;
        height: 40px;
        color: #fff;
        font-weight: bold;
        /* position: fixed;
        bottom: 20px;
        right: 1vw; */
        z-index: 999;
        object-fit: none;
        border-radius: 75%;
        cursor: pointer
    }
    .popup {
        width: 100%;
        height: 100vh;
        background: transparent;
    }
    .close-popup{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    }
    .popup-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 35px 35px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-family: 'Poppins', sans-serif;
        width: 60%;
        height:initial;
        display: flex;
        flex-direction: column;
        justify-content: start;
        font-size: 1.3vw;
        gap: 20px;
        border-right: 5px solid  #FF9900;
        border-bottom: 5px solid  #FF9900;
    }
    .popup-content h1{
        text-align: start;
        background-color: transparent;
        position: relative;
        top: -2vh;
        font-size: 20px;
    }.popup-content h1::after{
        content: "";
        position: absolute;
        bottom: -15%;
        left: 0;
        width: 50%;
        height: 5px;
        background-color: #FF9900;
    }
    
    .popup-content h2{
        text-align: start;
        text-transform: uppercase;
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;
        color: #4f4f4f;
        
    }
    .close-icon{
        position: absolute;
        top: 20%;
        font-size: 0;
        right: 1%;
        top: 2%;
        font-weight: 900;
        background: transparent;
        color: white;
        cursor: pointer;
    }
    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(1, 1, 1, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (min-width:426px) and (max-width:768px){
    .sticky-button {
        border-radius: 16px;
        /* background-color: rgb(10 10 13 / 20%); */
        background-color: #0400ff;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border: none;
        padding: 00px 20px;
        color: #fff;
        font-weight: bold;
        /* position: fixed;
        bottom: 15px;
        right: 4.8vw; */
        z-index: 999;
        border-radius: 30px;
        font-family: 'proxima-nova';
        font-weight: 900;
        cursor: pointer;
        text-transform: uppercase;
    }
    .btn{
        display: flex;
        height: 40px;
        position: fixed;
        bottom: 15px;
            right: 1%;
            z-index: 9999;
            gap: 10px;
    }
    
    .btn img {
        background-color: #FF9900;
        fill: #fff;
        border: none;
        /* padding: 8px 11px; */
        width: 40px;
        font-size: 20px;
        height: 40px;
        color: #fff;
        font-weight: bold;
        /* position: fixed;
        bottom: 20px;
        right: 1vw; */
        z-index: 999;
        object-fit: none;
        border-radius: 75%;
        cursor: pointer
    }
    .popup {
        width: 100%;
        height: 100vh;
        background: transparent;
    }
    .close-popup{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    }
    .popup-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 35px 35px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-family: 'Poppins', sans-serif;
        width: 60%;
        height:initial;
        display: flex;
        flex-direction: column;
        justify-content: start;
        font-size: 1.3vw;
        gap: 20px;
        border-right: 5px solid  #FF9900;
        border-bottom: 5px solid  #FF9900;
    }
    .popup-content h1{
        text-align: start;
        background-color: transparent;
        position: relative;
    }.popup-content h1::after{
        content: "";
        position: absolute;
        bottom: -15%;
        left: 0;
        width: 50%;
        height: 5px;
        background-color: #FF9900;
    }
    
    .popup-content h2{
        text-align: start;
        text-transform: uppercase;
        background-color: transparent;
    }
    .close-icon{
        position: absolute;
        top: 20%;
        font-size: 0;
        right: 1%;
        top: 2%;
        font-weight: 900;
        background: transparent;
        color: white;
        cursor: pointer;
    }
    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(1, 1, 1, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
 
@media screen and (max-width:426px){
    .sticky-button {
        border-radius: 16px;
        /* background-color: rgb(10 10 13 / 20%); */
        background-color: #0400ff;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border: none;
        padding: 00px 20px;
        color: #fff;
        font-weight: bold;
        /* position: fixed;
        bottom: 15px;
        right: 4.8vw; */
        z-index: 999;
        border-radius: 30px;
        font-family: 'proxima-nova';
        font-weight: 900;
        cursor: pointer;
        text-transform: uppercase;
    }
    .btn{
        display: flex;
        height: 40px;
        position: fixed;
        bottom: 4%;
            right: 1%;
            z-index: 9999;
            gap: 10px;
    }
    
    .btn img {
        background-color: #FF9900;
        fill: #fff;
        border: none;
        /* padding: 8px 11px; */
        width: 40px;
        font-size: 20px;
        height: 40px;
        color: #fff;
        font-weight: bold;
        /* position: fixed;
        bottom: 20px;
        right: 1vw; */
        z-index: 999;
        object-fit: none;
        border-radius: 75%;
        cursor: pointer
    }
    .popup {
        width: 100%;
        height: 100vh;
        background: transparent;
    }
    .close-popup{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    }
    .popup-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 35px 35px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-family: 'Poppins', sans-serif;
        width: 90%;
        height:initial;
        display: flex;
        flex-direction: column;
        justify-content: start;
        font-size: 1.3vw;
        gap: 20px;
        border-right: 5px solid  #FF9900;
        border-bottom: 5px solid  #FF9900;
    }
    .popup-content h1{
        text-align: start;
        background-color: transparent;
        position: relative;
        font-size: 15px;
        top:-2vh;
    }.popup-content h1::after{
        content: "";
        position: absolute;
        bottom: -15%;
        left: 0;
        width: 50%;
        height: 5px;
        background-color: #FF9900;
    }
    
    .popup-content h2{
       
        text-align: justify;
        text-transform: uppercase;
        background-color: transparent;
        font-size: 12px;
        font-weight: 500;
        color: #706f6f;
    }
    .close-icon{
        position: absolute;
        top: 20%;
        font-size: 0;
        right: 1%;
        top: 2%;
        font-weight: 900;
        background: transparent;
        color: white;
        cursor: pointer;
    }
    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(1, 1, 1, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}