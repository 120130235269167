body{
  font-family: 'Poppins', sans-serif;
}

.mobile{
  display: none;
}

@media screen and (max-width:950px) {
  .mobile{
    display: flex;
    width: 40px;
    height: 40px;
    color: #fff;
    align-items: center;
    font-size: 15px;
    justify-content: center;
    text-shadow: 1px 1px solid #fff;
    border: 1px solid #fff2;
    border-radius: 10px;
  }
}

span.home{
  position:relative;
  width:100%;
  display:flex;
  height:fit-content;
  z-index: 11;
  grid-column: 1/4;
}