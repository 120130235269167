.not-found{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3vw;
}
.not-found h1 {
    font-size: 7vw;
    color: #fff;
}
.not-found h3{
    font-size: 4vw;
    color: #fff;
}