
/* Style for the container */


/* style for the login username warning  */
.username-info-text{
  color: #e11c1c;
  font-size: 14px;
}
  .auth-container {
    width: 100%;
    min-height: 90vh;
    display: flex;
    padding: 0 2%;
  }
  
  /* Style for the child divs */
  .auth-container .container-left {
    width: 50%;
    max-height: 85vh;
    background-color: #1C1B1B;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 2% 0 2% 2%; */
  }
  .auth-container .container-left img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  .auth-container form{
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
   
  }
  .auth-container .form-group {
    position: relative;
    width: 100%;
    
  }
  
  .auth-container .container-right {
    width: 50%;
    height: 100%;
    padding: 0 4%;
    background-color: #1C1B1B;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
  }
  .auth-container .container-right h2{
    color:#fff;
    font-size: 34px;
  }
  .auth-container .container-right p {
    color: #F8981D;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  
  .auth-container h2 {
    font-size: 24px;
  }
  
  .auth-container .form-group {
    margin-bottom: 10px;
  }
  
  .auth-container label {
    display: block;
    color: #FFF5;
    font-size: 14px;
    position: absolute;
    top: -9px;
    background: #1C1B1B;
    left: 3%;
  }
  
  .auth-container input {
    width: 100%;
    padding: 20px 10px;
    border: 2px solid #fff3;
    border-radius: 5px;
    background-color: transparent !important;
    color: #fff;
  }
  .auth-container input:focus-visible{
    outline: 2px solid #F8981D;
    border: none;
  }
  .auth-container button {
    width: 100%;
    background-color: #F8981D;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    padding: 15px 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* button:hover {
    background-color: #0056b3;
  } */
  
  .auth-container .data-container {
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 30px;
  } 
  .auth-container .data-container .box {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    background: #242424;
    height: 100%;
    padding-top: 10px;
  }
  .auth-container .data-container .box h1 {
    color: #F8981D;
    text-align: center;
    font-weight: 300;
    font-size: 30px;
  }
  .auth-container .data-container .box p{
    color:#fff9;
    margin-bottom: 0;
    text-align: center;
    font-size: 12px;
  }
  .auth-container .links{
    margin-top:20px;
    width: 100%;
    display: flex;
    align-items: center;  
    justify-content: center;
    flex-direction: column;
    gap: 5px;
  }
  .auth-container .links p{
    font-size: 14px;
    margin-bottom: 0;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 1px;
  }
  .auth-container .links a{
    font-size: 14px;
    margin-bottom: 0;
    color: #F8981D;
    text-decoration: none;
  }
  .auth-container p.error-message {
    color: #F8981D;
    font-size: 12px;
    margin-bottom:0;
  }
  
/*------------ pop up----------- */
.forgot-pass-popup{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  backdrop-filter: blur(4px);
  font-family: "proxima-nova";
}
.forgot-pass-em{
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.forgot-pass-contet{
  height: 40%;
  width: 45%;
  /* background-color: #F8981D; */
  z-index: 999;
  border-right: 5px solid #FF9900;
  border-bottom: 5px solid #FF9900;
  border-radius: 10px;
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fogot-pass-text{
  padding: 2% 2%;
  font-size: 22px;
  font-weight: 600;
}
.close-btn {
  width: 100%;
  display: flex;
  text-align: end;
  justify-content: end;
  padding: 2% 4%;
  cursor: pointer;
}
.close-btn p{
  background-color: #FF9900;
  padding: 1.5% 4%;
  border-radius: 4px;
  font-size: 20px;
}
.close-btn p:hover{
  background-color: #eb8500;
}
  @media screen and (max-width: 950px){
    .forgot-pass-contet{
      width: 75%;
      height: 20%;
    }
    .fogot-pass-text{
      font-size: 14px;
    }
    .close-btn p{
      font-size: 14px;
    }
    .auth-container {
     flex-direction: column;
     gap: 20px;
     margin-top: 100px;
     min-height: 100vh;
     margin-bottom: 50px;
    }
    .auth-container .heading{
      margin: 10px 0 15px;
    }
    .auth-container .heading p{
      color: #F8981D;
    }
    .auth-container .container-right{
      width:100%; 
      height: 50%;
    }
    .auth-container .container-left{
      width:100%;
      padding: 10px;
      height: 30%;
    }
    .auth-container .container-left img{
      object-position: top;
    }
    .auth-container input {
      padding: 10px 10px;
    }
    .auth-container label {
      font-size: 12px;
      top: -8px;
  }
  .auth-container button {
    font-size: 16px;
    padding: 10px 20px;
  }
  .auth-container .links {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .auth-container .links p {
    font-size: 9px;
    text-align:center;
  }
  .auth-container .links a {
    font-size: 8px;
  }
  .auth-container .container-right h2 {
    font-size: 18px;
  }
  .auth-container .container-right p {
    margin-bottom: 0;
    font-size: 9px;
    line-height: 1.3;
  }
  .auth-container .data-container .box h1 {
    font-size: 13px;
  }
  .auth-container .data-container .box {
    width: 23%;
    gap: 8px;
    padding: 3px 0;
    height: 80px;
    justify-content: space-evenly;
  }
  .auth-container .data-container {
    gap: 10px;
    padding-bottom: 25px;
  }
  .auth-container .data-container .box p {
    font-size: 8px;
  }
  .auth-container form {
    gap: 10px;
  }
  .auth-container p.error-message {
    color: #F8981D;
    font-size: 10px;
    margin-top: 5px;
  }
  }