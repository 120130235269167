.cart-container{
    width: 100%;
    height: 100vh;
    color: #fff;
    margin-top: 20vh;
}
.cart-heading{
    width: 100%;
    text-align: center;
}
.cart-items{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 10%;
}
.cart-items .cart-item {
    width: 64%;
    height: 10%;
}
.cart-items .cart-item  .cart-item-desc{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 16px;

}
.cart-items .cart-item  .cart-item-desc .item-title{
    font-size: large ;
}
.cart-itme-id{
    color: #fff;
    font-size: 10px;
    font-family: "proxima-nova";
}
.readmoreless {
    color: #00CBFF;
    font-family: 'proxima-nova';
    font-weight: 800;
    cursor: pointer;
}
.vid-desc{

}
.cart-item .cart-title{
    text-align: center;

}
.cart-desc{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.cart-item-container{
    height: 60vh;
    overflow-y: scroll;
}
.cart-item-container::-webkit-scrollbar{
    display: none;
}
.cart-last{
    border-top: 2px solid grey;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 19.6vh;
}

.cart-item {
    display: flex;
    width: 100%;
    height: 112px;
    align-items: center;
    justify-content: center;
    gap: 4%;
    margin-bottom: 3%;
}

.cart-item .cart-title {
    text-align: start;
    width: 100%;
    padding: 2% 0;
}

.item-title {
    color: #fff;
    font-size: small;
    font-family: "proxima-nova";
    font-weight: 600;
}

.cart-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.cart-img {
    height: 100%;
    width: 44%;
    object-fit: cover;
}

.cart-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.cart-item-desc {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 46%;
    justify-content: space-between;
}



button.item-remove-btn {
    border: none;
    background: #aeaeae;
    height: 32%;
    font-family: "proxima-nova";
}

p.cart-item-coins {
    color: #fff;
    font-size: 10px;
    font-family: "proxima-nova";
}

