.cancel-page{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 100px;
}

.cancel-page h1{
font-size: 40px;
text-transform: uppercase;
color: #fff;
}


@media screen and (max-width:950px) {
    .cancel-page{
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 100px;
    }
    
    .cancel-page h1{
    font-size: 25px;
    text-transform: uppercase;
    color: #fff;
    }
}