.carousel {
    height: 40vh;
    width: 100%;
    position: relative;
    background: #1C1B1B;
}
.carousel-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.carousel-dots {
    background: transparent;
}
.dot.active{
    background: #ffffff;
    opacity: 100%;
}
.dot {
    cursor: pointer;
    height: 7px;
    width: 30px;
    border-radius: 15px;
    margin: 0 6px;
    background-color: #FFFFFF;
    opacity: 42%;
    border-radius: 0%;
    display: inline-block;
    transition: background-color 0.6s ease;
}
.carousel-details {
    position: absolute;
    bottom: 10%;
    display: flex;
    padding: 0 2%;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    height: auto;
    background: transparent;
    color: #ffffff;
}
.carousel-offer{
    background-color: transparent;
}
.carousel-offer p {
    background-color: transparent;
    font-size: 25px;
    font-weight: 500;
    font-family: "proxima-nova";
    text-transform: uppercase;
}
.carousel-offer h2 {
    background-color: transparent;
    font-weight: 800;
    font-family: "proxima-nova";
    font-size: 25px;
    text-transform: uppercase;
}
.carousel-offer button {
    height: 2.5vw;
    min-width: 11.5vw;
    font-family: 'Poppins', sans-serif;
    color: #000000;
    background-color: #FF9900;
    border: none;
    display: flex;
    font-size: 20px;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 30px;
    font-family: 'proxima-nova';
    padding: 0 1vw;
    gap: 0.5vw;
}
.carousel-offer button span {
    background-color: transparent;
    font-size: 20px;
    font-weight: 800;
    font-family: 'proxima-nova';
}
.carousel-offer button p {
    background-color: transparent;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'proxima-nova';
    font-size: 13px;
}

.carousel-title{
    background: transparent;
}
.carousel-title h2 {
    background: transparent;
    font-size: 40px;
    font-weight: 900;
    font-family: 'proxima-nova';
}



@media screen and (min-width:768px) and (max-width:1027px){
    .carousel-offer button {
        height: 3vw;
        width: 18vw;
        font-family: 'Poppins', sans-serif;
        color: #000000;
        background-color: #FF9900;
        border: none;
        display: flex;
        font-size: 25px;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 30px;
    }
    
    .dot {
        cursor: pointer;
        height: 5px;
        width: 20px;
        border-radius: 15px;
        margin: 0 6px;
        background-color: #FFFFFF;
        opacity: 42%;
        border-radius: 0%;
        display: inline-block;
        transition: background-color 0.6s ease;
    }

}

@media screen and (min-width:425px) and (max-width:768px){
   
    .carousel-title h2 {
        background: transparent;
        font-size: 30px;
        font-weight: 800;
    }
    .carousel-offer button {
        height: 100%;
        width: auto;
        font-family: 'Poppins', sans-serif;
        color: #000000;
        background-color: #FF9900;
        border: none;
        display: flex;
        font-size: 20px;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 30px;
    }
    .carousel {
        position: relative;
        top: 20vh;
    }
    .carousel-details {
        position: absolute;
        bottom: 10%;
        display: flex;
        padding: 0 1vw;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        height: auto;
        background: transparent;
        color: #ffffff;
    }
    .dot {
        cursor: pointer;
        height: 4px;
        width: 15px;
        border-radius: 15px;
        margin: 0 6px;
        background-color: #FFFFFF;
        opacity: 42%;
        border-radius: 0%;
        display: inline-block;
        transition: background-color 0.6s ease;
    }
}

@media screen and (max-width:425px){
    
    .carousel-title h2 {
        background: transparent;
        font-size: 40px;
        font-weight: 800;
        display: none;
    }
    .carousel-offer button {
        height: 100%;
        min-width: auto;
        font-family: 'Poppins', sans-serif;
        color: #000000;
        background-color: #FF9900;
        border: none;
        display: flex;
        font-size: 20px;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 30px;
        padding: 0 20px;
        gap: 10px;
    }
    .carousel {
        height: auto;
        top:25vh;
        width: 100%;
        position: relative;
    }

    .btnlink{
        text-decoration: none;
    }
    .carousel-details {
        position: absolute;
        bottom: 5%;
        display: flex;
        flex-direction: column;
        padding: 0 1vw;
        justify-content: space-between;
        align-items: start;
        width: 100%;
        height: auto;
        background: transparent;
        color: #ffffff;
    }
 .dot{
    height: 2px;
    width: 22px;
 }
 .carousel-dots{
    position: relative;
    left: 35%;
 }
    
}