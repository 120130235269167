.red-videos{
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
.red-videos .header{
    position: fixed;
}

.red-email{
    color: #F8981D;
}
.red-videos h1{
    font-size: 30px;
    color: #fff;
}

.red-videos p{
    font-size: 20px;
    color: #fff;
}