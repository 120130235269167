.auth-header {
    height: 10vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.user-register{
    position: absolute;
    top: 100%;
    right: -50%;
    list-style: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    z-index: 9;
}
.name{
    text-transform: capitalize;
}
.login-btn{
   display: none;
    top: 100%;
    padding: 13px 20px;
    background: #000;
    text-decoration: none;
    color: #fff;
    transition: 0.2s linear;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    position: relative;
    left: 70%;
}
/* this is nummber of vids in cart */
.icon span{
    position: absolute;
    top: -4px;
    right: -8px;
    height: 16px;
    width: 16px;
    background-color: #F8981D;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
}
.header .user-info{
    position: absolute;
    left: -250%;
    top: -90%;
    background: #000;
    padding: 4px 15px;
    display: none;
    color: #fff;
    font-size: 12px;
    border-radius: 5%;
    border: 1px solid #fff;
}
.header .right .icon:nth-child(2):hover .user-info{
    display: block;
}

.login-btn:hover{
  background-color: #333333;
}
.icon{
    position: relative;
}
.icon:hover .login-btn{
        display: block;
}
.header .drawer.open, .header .cartItems .open{
    transform: translateX(0);
}
#cartPopUp{
    z-index: 9999;
    padding-top: 2vw;
    display: block;
}
#cartPopUp::-webkit-scrollbar{
    width: 2px;
}
.cart-close-btn{
    color: #FF9900;
    display: flex;
    align-items: center;
    height: 8vh;
    /* margin-top: 6vh; */
    margin: 0 1vw;
    /* width: 43%; */
    justify-content: space-between;
    font-size: 1.4rem;
    cursor: pointer;

}
.header .drawer, .header .cartItem{
            display: flex;
            width: 30%;
            height: 100vh;
            background-color: #333333;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 1;
            transform: translateX(+100%);
            transition: 0.3s linear;
            overflow-y: auto;
}
    
.header .drawer ul {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 40px;
            padding-top: 150px;
            text-decoration: none;
            padding-left: 40px;
            list-style: none;
}
.header .drawer a{
            text-decoration: none;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
}
.header .drawer a span{
            color: #FF9900;
            padding-left: 5px;
}

.name-coins{
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
}
.name-coins .avl{
    color: #FFF;
}
.name-coins span{
    color:  #FF9900;
}
.header{
    display: grid;
    width: 100%;
    justify-content: space-between;
    grid-template-columns: 20% 60% 20%;
    align-items: center;
    height: 130px;
    background-color: #1C1B1B;
    padding: 0 2%;
    position: fixed;
    top: 0;
    z-index: 999;
    margin-top:2vw;
}



.header .right {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
  }
  
  .icon {
    cursor: pointer;
  }
  .icon svg{
    width: 30px;
    height: 25px;
}
.checkbox-search .hash-logo{
    display: none;
}

.header .header-navmenu{
    width: 100%;
    display: flex;
    grid-column: 1/4;
    justify-content: start;
    align-items: center;
    overflow-x: auto;
}
.header .header-navmenu::-webkit-scrollbar {
    height: 0;
  }

.header .header-navmenu ul{
    display: flex;
    gap: 4.5vw;
    list-style: none;
}
.header .header-navmenu li a {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-family: "proxima-nova";
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.header .header-navmenu li  input{
position: absolute;
width: 100%;
justify-content: center;
height: 100%;
display: flex;
cursor: pointer;
opacity: 0;
}

.header .header-navmenu li  input:checked ~b{
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #FF9900;
    bottom: 0;
}



.header .header-navmenu li{
    text-decoration: none;
    color: #fff;
    position: relative;
    padding: 10px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "proxima-nova";
    width: max-content;
    cursor: pointer;
    position: relative;
    }

    .header .header-navmenu li:hover p{
        display: flex;
    }

    .header .header-navmenu li p{
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        top: 00%;
        background-color: #1C1B1B;
        border: 1px solid #fff;
        display: none;
        z-index: 1;
    }

    .header .header-navmenu li span{
        background: red;
        padding: 2px 5px;
    }
    .header .header-navmenu li .dropdown-content {
        position: absolute;
        top: 100%;
        background-color: #000;
        padding: 5px 10px;
        min-width: 100%;
    }
    .dropdown-content a{
        font-size: 12px;
        padding: 10px 0px;
        width: 100%;
        display: flex;
    }

    .header .hamburger-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px; 
        height: 20px; 
        cursor: pointer;
        z-index: 9999;
      }
      
      .header .bar {
        width: 100%;
        height: 3px;
        background-color: #F8981D;
        transition: transform 0.3s, opacity 0.3s;
      }

      
      
      .header .bar.active:nth-child(1) {
        transform: translateY(11px) rotate(45deg);
    }
      
    .header .bar.active:nth-child(2) {
        opacity: 0;
      }
      
      .header .bar.active:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
      }

.header .header-navmenu li.active::after{
content: "";
width: 100%;
position: absolute;
bottom: 0;
left: 0;
height: 2px;
background-color: #F8981D;
    }
.header .logo{
    cursor: pointer;
    text-decoration: none;
}

.header .logo h2{
    color: #06ff27;
    font-size: 20px;
    line-height: 1;
    width: fit-content;
    height: fit-content;
    position: relative;
}
.header .logo h2::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #f703ff;
    z-index: -1;
}

.burger-menu{
    display: block;
    z-index: 111;
}

.header .burger-menu.active{ 
    position: fixed;
    right: 3%;
  }

.b-menu {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 20px;
    position: relative;
  }

  .b-menu span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #333;
    border-radius: 9px;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.3s ease-in-out;
  }
  .b-menu span:nth-child(1) {
    top: 0;
    background-color: orange;
    width: 30px;
  }
  
  .b-menu span:nth-child(2) {
    top: 8px;
    background-color: orange;
    width:30px;
  }
  .b-menu span:nth-child(3){
    top: 16px;
    background-color: orange;
    width:30px;
  }
  
  .burger-menu.active span:nth-child(1) {
    top: 9px;
    transform: rotate(45deg);
    width: 100%;
    left: 0;
  }
  
  .burger-menu.active span:nth-child(2) {
    opacity: 0;
  }
  
  .burger-menu.active span:nth-child(3) {
    top: 9px;
    transform: rotate(-45deg);
    width: 100%;
    left: 0;
  }
   .burger-menu.active{
width: auto;
}
.image-2 {
    display: none;
}
.details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10vw;
    height: 40px;
    color: #FFFFFF;
    position: relative;
    z-index: 1;
}
.details .profile{
    width: initial;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.details .profile-dropdown{
    width: 100%;
    height: 0px;
    border-radius: 3px;
    position: absolute;
    top: 110%;
    left: 0;
    z-index: -1;
    transition: 0.3s linear;
    opacity: 0;
    text-align: center;
    font-size: 20px;
    background-color: #FF9900;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
 .new-video-btn{
    width: 150px;
    height: 100%;
    background-color: #00CBFF;
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
    border-radius: 3px;
}

 .new-video-btn svg{
    width: 20px;
    height: 20px;
}

.details:hover .profile-dropdown{
    height: 50px !important;
    opacity: 1;
}
.details .new-video-btn:hover .profile-dropdown{
    height: 0px !important;
    opacity: 0; 
}

.profile-name p {
    text-transform: capitalize;
    font-family: 'proxima-nova';
}
.profile img{
    width: 3vw;
}


.search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.search-bar svg{
    position: absolute;
    width: 20px;
    left: 3%;
}

.search-bar:focus{
    border: none;
}
.search-bar input {
    width: 100%;
    padding: 15px;
    font-size: 14px;
    border: none;
    background-color: #000000;
    color: #FFFFFF;
    border-radius: 10px;
}


.search-bar input[type="text"],
.search-bar input[type="placeholder"]{
    padding-left: 70px;
    border: none;
    text-transform: capitalize;
    font-family: "proxima-nova";
    font-weight: 500;
}
.search-icon{
    position: absolute;
    left: 5%;
    width: 20px;
    background: transparent;
}

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 13%;
    color: #FFFFFF;
}

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    color: #FFFFFF;
    margin-left: auto;
    gap: 10px;
}

.checkbox-search{
    width: 100%;
    display: flex;
    height: 40px;
    margin-top: 20px;
    grid-column: 1/4;
    grid-row: 2/3;
    margin-bottom: 10px;
}
.checkbox-search ul{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    list-style: none;
    gap: 1%;
    overflow-x: auto;
    padding: 0 0 0 2%;

}

.checkbox-search ul::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  
  /* Track */
  .checkbox-search ul::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .checkbox-search ul::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .checkbox-search ul::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .all-checked:checked ~ span{
    background-color: #FF9900;
  }
.checkbox-search ul span{
    min-width: 110px;
    height: 35px;
    border-radius: 20px;
    border: 1px solid #363636;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    z-index: 0;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
}

.checkbox-search ul li{
    position: relative;
}

.checkbox-search ul input{
    position: absolute;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
}
.checkbox-search ul input:checked ~ span{
    background-color: #FF9900;
}
/* this is the popup area */

.logout-pop-up{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    backdrop-filter: blur(4px);
    font-family: "proxima-nova";
}
.logout-pop-up-content{
    height: 40%;
    width: 45%;
    /* background-color: #F8981D; */
    z-index: 999;
    border-right: 5px solid #FF9900;
    border-bottom: 5px solid #FF9900;
    border-radius: 10px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2%;
}
.pop-up-em{
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}
.logout-confirmation p{
    font-size: 22px;
    font-weight: 600;
}
.logout-confirmation-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10%;
}
span.cancel-logout, span.approve-logout {
    background: rgb(0, 255, 0);
    padding: 1% 6%;
    border-radius: 5px;
    cursor: pointer;
}
span.approve-logout{
    background: #FF9900;
}
@media screen and (max-width:950px)  {
    .logout-pop-up-content {
        height: 15%;
        width: 75%;
    }
    .logout-confirmation p{
        font-size: 14px;
    }
    .name-coins {
        width: 100%;
        gap: 36px;
        justify-content: space-between;
        align-items: start;
        flex-direction: column;
    }
    .header .drawer.open, .header .cartItem .open{
    transform: translateX(0);
    }

    .header .tags{
        position: relative;
        z-index: 11;
    }

    .header .tags:hover ul{
        display: flex;
        flex-direction: column;
    }

    .header .tags ul{
        list-style: none;
        position: absolute;
        left: 0;
        top: 100%;
        min-width: 40px;
        height: 50vh;
        border-radius: 3px;
        display: none;
        overflow: auto;
        padding-top: 20px;
    }
    .header .tags ul::-webkit-scrollbar {
        width: 0px;
      }

    .header .tags ul li{
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 20px;
        background-color: #1C1B1B;
        border-bottom: 1px solid #fff2;
    }
    
    .header .tags ul li:hover{
        background-color: #fff;
        color: #F8981D;
    }


    .header .drawer, .header .cartItem{
            display: flex;
            width: 85%;
            height: 100vh;
            background-color: #333333;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 1;
            transform: translateX(+100%);
            transition: 0.3s linear;
    }
    
    .header .drawer ul {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 40px;
            padding-top: 150px;
            text-decoration: none;
            padding-left: 20px;
            list-style: none;
    }
    .header .drawer a{
            text-decoration: none;
            color: #fff;
    }


    .header{
        display: grid;
        width: 100%;
        /* position: fixed; */
        justify-content: space-between;
        grid-template-columns: 20% 20% 20% 20% 20%;
        align-items: center;
        /* height: -moz-fit-content; */
        height: auto;
        background-color: #1C1B1B;
        padding: 3px 2% 0 2%;
        position: fixed;
        row-gap: 10px;
        top: 17px;
        z-index: 999;
        border-bottom: 1px solid #333333;
    }



    .header .right {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
    grid-row: 1/2;
    grid-column: 5/6;
    }

  .header .right .icon{
    display: none;
  }

  .icon svg{
    width: 30px;
    height: 25px;
    }
    .checkbox-search .hash-logo{
    display: none;
    }

    .header .header-navmenu{
    width: 100%;
    display: flex;
    grid-column: 1/6;
    justify-content: center;
    align-items: center;
    }
    

    .header .header-navmenu ul{
    display: flex;
    gap: 20px;
    list-style: none;
    width: 100%;
    overflow-x: auto;
    }

    .header .header-navmenu li p{
        top: 0;
    }

    .header .header-navmenu ul::-webkit-scrollbar {
    width: 0px;
    height: 0;
    }
    .header .header-navmenu li a {
    text-decoration: none;
    color: #fff;
    font-size: 10px;
    font-family: "proxima-nova";
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    }

    .header .header-navmenu li  input{
    position: absolute;
    width: 100%;
    justify-content: center;
    height: 100%;
    display: flex;
    cursor: pointer;
    opacity: 0;
    }

    .header .header-navmenu li  input:checked ~b{
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #FF9900;
    bottom: 0;
    }

    .header .header-navmenu li{
    text-decoration: none;
    color: #fff;
    position: relative;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "proxima-nova";
    min-width: max-content;
    }
    .header .header-navmenu li span{
        background: red;
        padding: 2px 5px;
    }
    .header .header-navmenu li .dropdown-content {
        position: absolute;
        top: 100%;
        background-color: #000;
        padding: 5px 10px;
        min-width: 100%;
    }
    .dropdown-content a{
        font-size: 12px;
        padding: 10px 0px;
        width: 100%;
        display: flex;
    }

    .header .hamburger-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px; 
        height: 20px; 
        cursor: pointer;
        z-index: 9999;
      }
      
      .header .bar {
        width: 100%;
        height: 3px;
        background-color: #F8981D;
        transition: transform 0.3s, opacity 0.3s;
      }
      
      .header .bar.active:nth-child(1) {
        transform: translateY(11px) rotate(45deg);
    }
      
    .header .bar.active:nth-child(2) {
        opacity: 0;
      }
      
      .header .bar.active:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
      }

    .header .header-navmenu li.active::after{
    content: "";
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #F8981D;
    }
    .header .logo{
    cursor: pointer;
    text-decoration: none;
    grid-column: 2/5;
    display: flex;
    justify-content: center;
    }

    .header .logo h2{
    color: #06ff27;
    font-size: 20px;
    line-height: 1;
    width: fit-content;
    height: fit-content;
    position: relative;
    }

    .header .logo h2::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #f703ff;
    z-index: -1;
    }

    .burger-menu{
    display: flex;
    z-index: 111;
    grid-column: 5/6;
    grid-row: 1/2;
    }

    .header .burger-menu.active{ 
        position: fixed;
        right: calc(2% + 21px);
      }

    .b-menu {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 20px;
    position: relative;
    }

    .b-menu span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #333;
    border-radius: 9px;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.3s ease-in-out;
    }
    .b-menu span:nth-child(1) {
    top: 0;
    background-color: orange;
    width: 30px;
    }
  
    .b-menu span:nth-child(2) {
    top: 8px;
    background-color: orange;
    width:30px;
    }
    .b-menu span:nth-child(3){
    top: 16px;
    background-color: orange;
    width:30px;
    }
  
    .burger-menu.active span:nth-child(1) {
    top: 9px;
    transform: rotate(45deg);
    width: 100%;
    left: 0;
    }
  
    .burger-menu.active span:nth-child(2) {
    opacity: 0;
    }
  
    .burger-menu.active span:nth-child(3) {
    top: 9px;
    transform: rotate(-45deg);
    width: 100%;
    left: 0;
    }
   .burger-menu.active{
    width: auto;
    }
    .image-2 {
    display: none;
    }
    .details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10vw;
    height: 40px;
    color: #FFFFFF;
    position: relative;
    z-index: 1;
    }
    .details .profile{
    width: initial;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .details .profile-dropdown{
    width: 100%;
    height: 0px;
    border-radius: 3px;
    position: absolute;
    top: 110%;
    left: 0;
    z-index: -1;
    transition: 0.3s linear;
    opacity: 0;
    text-align: center;
    font-size: 20px;
    background-color: #FF9900;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .new-video-btn{
    width: 150px;
    height: 100%;
    background-color: #00CBFF;
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
    border-radius: 3px;
    }

    .new-video-btn svg{
    width: 20px;
    height: 20px;
    }

    .details:hover .profile-dropdown{
    height: 50px !important;
    opacity: 1;
    }

    .details .new-video-btn:hover .profile-dropdown{
    height: 0px !important;
    opacity: 0; 
}

.profile-name p {
    text-transform: capitalize;
    font-family: 'proxima-nova';
}
.profile img{
    width: 3vw;
}


.search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    grid-column: 1/6;
    grid-column: 2.3;
}

.search-bar svg{
    position: absolute;
    width: 20px;
    left: 3%;
}

.search-bar:focus{
    border: none;
}
.search-bar input {
    width: 100%;
    padding: 15px;
    font-size: 14px;
    border: none;
    background-color: #000000;
    color: #FFFFFF;
    border-radius: 10px;
}


.search-bar input[type="text"],
.search-bar input[type="placeholder"]{
    padding-left: 70px;
    border: none;
    text-transform: capitalize;
    font-family: "proxima-nova";
    font-weight: 500;
}
.search-icon{
    position: absolute;
    left: 5%;
    width: 20px;
    background: transparent;
}

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 13%;
    color: #FFFFFF;
}

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    color: #FFFFFF;
    margin-left: auto;
    gap: 10px;
}

.checkbox-search{
    width: 100%;
    display: flex;
    height: 40px;
    padding: 0 0 0 2%;
    margin-top: 20px;
    grid-column: 1/4;
    grid-row: 2/3;
    display: none;
}
.checkbox-search ul{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    list-style: none;
    gap: 1%;
    overflow-x: auto;
}

.checkbox-search ul::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  
  /* Track */
  .checkbox-search ul::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .checkbox-search ul::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .checkbox-search ul::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.checkbox-search ul span{
    min-width: 110px;
    height: 35px;
    border-radius: 20px;
    border: 1px solid #363636;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    z-index: 0;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
}

.checkbox-search ul li{
    position: relative;
}

.checkbox-search ul input{
    position: absolute;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
}
.checkbox-search ul input:checked ~ span{
    background-color: #FF9900;
}


}
.cart-page span{
    font-size: 1.5vw;
    font-weight: 800;
}

/* .cart-page{
    width: 100%;
    height: 8%;
    text-align: center;
    display: flex;
    border-top: 2px solid #6a6a6a;
    border-bottom: 2px solid #6a6a6a;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5%;
    color: #fff;    
} */
span.name-and-coins {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 2% 3%;
    color: #ffffff;
    height: 6vh;
}
/* this is ---------------------bottom of the drawer  */
.est-total {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    color: #fff;
}
p.est-total-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3%;
}
p.est-total-bottom {
    padding: 0 3%;
    height: auto;
    width: 100%;
}
.chekout-btn{
    border: none;
    color: #fff;
    width: 100%;
    display: block;
    /* text-align: center; */
    /* height: 18%; */
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background:  #FF9900;
    cursor: pointer;
}