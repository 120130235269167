.marquee{
    overflow: hidden;
    height:2vw;
    background-color:#F8981D;
    position: relative;
      top: 0;
      left: 0;
    z-index:11111;
    width:100%;
    margin-bottom:10px;
    position: fixed;
  }
  
  .announcment_bar{
    width:max-content;
    display:flex;
    height:100%;
    align-items:center;
    justify-content:center;
    gap:1.47vw;
     position: absolute;
    overflow: hidden;
  
    animation: marquee 40s linear infinite;
  }
  
  .announcment_bar p{
    font-family: "proxima-nova";
    color: #000;
    font-size: 0.9vw;
    float: left;
    font-weight: 700;
    letter-spacing: 0;
  }
  
  @keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
  }
  

  @media screen and (max-width:950px) {
    .marquee{
      overflow: hidden;
      height: 25px;
      background-color: #F8981D;
      position: relative;
      top: 0;
      left: 0;
      z-index: 11111;
      width: 100%;
      margin-bottom: 10px;
      position: fixed;
    }
    
    .announcment_bar{
      width:max-content;
      display:flex;
      height:100%;
      align-items:center;
      justify-content:center;
      gap:20px;
       position: absolute;
      overflow: hidden;
    
      animation: marquee 40s linear infinite;
    }
    
    .announcment_bar p{
      font-family: "proxima-nova";
      color:#000;
      font-size:12px;
      letter-spacing:0.1vw;
      float: left;
      font-weight: 700;
    }
    
    @keyframes marquee {
      0% { left: 0; }
      100% { left: -100%; }
    }
    
  }