
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* text-transform: uppercase; */
} 
a{
  text-decoration: none;
}
body{
  background-color: #1C1B1B;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.Toastify{
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
}