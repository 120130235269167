.logout-popup{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
}
.logout-popup-content{
    width: 50%;
    height: 36%;
    background-color: rgb(0, 136, 255);
    
}