

.categories-sub{
    width: 100%;
    height: 60vh;
    background-color: #1C1B1B;
    padding: 2% 2%;
    gap: 2%;
    display: flex;
    border-bottom: 1px solid #000;
    position: absolute;
    left: 0;
    top: 0;
}

.categories-sub .btns{
    width: 20%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.categories-sub .btns a{
    border-radius: 10px;
    width: 45%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #171717;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 13px;
}



.categories-sub .btns a:nth-child(3){
    width: calc(100% - 25px);
}
.categories-sub .btns a:nth-child(4){
    width: calc(100% - 25px);
    background-color: #FF0A0A;
}

.categories-sub .categories{
    min-width: 60%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 10px;
 
}

.categories-sub .categories a{
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5%;
    justify-content: center;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

.categories-sub .categories a img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    z-index: -1;
}


@media screen and (max-width:950px) {
    .categories-sub{
        width: 100%;
        min-height: 60vh;
        height: fit-content;
        background-color: #1C1B1B;
        padding: 2% 2%;
        gap: 20px;
        display: flex;
        border-bottom: 1px solid #000;
        position: absolute;
        left: 0;
        top: 0;
        flex-direction: column;
    }
    
    .categories-sub .btns{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
    .categories-sub .btns a{
        border-radius: 10px;
        width: 45%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #171717;
        color: #FFFFFF;
        text-transform: uppercase;
        font-size: 13px;
    }
    
    
    
    .categories-sub .btns a:nth-child(3){
        width: calc(100% - 25px);
    }
    .categories-sub .btns a:nth-child(4){
        width: calc(100% - 25px);
        background-color: #FF0A0A;
    }
    
    .categories-sub .categories{
        min-width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 10px;
    }
    
    .categories-sub .categories a{
        width: 100%;
        height: 200px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 5%;
        justify-content: center;
        color: #fff;
        font-size: 13px;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
    }
    
    .categories-sub .categories a img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        z-index: -1;
    } 
}