.footer{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #333333;
}

.footer h4{
    color: #fff;
    font-size: 13px;
}