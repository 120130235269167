.success-page{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
    gap: 50px;
}
.success-page h1{
    font-size: 40px;
    color: #fff;
    text-transform: uppercase;
}
.success-page .video-section{
    width: 60%;
    height: 60%;
}
.success-page .video-section video{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
@media screen and (max-width:950px) {
    .success-page{
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 100px;
        gap: 100px;
        background-color: #fff;
    }
    .success-page h1{
        font-size: 25px;
        color: #000;
        text-align: center;
        line-height: 1.3;
        text-transform: uppercase;
    }
    
    .success-page .video-section{
        width: 100%;
        height: auto;
    }
    
    .success-page .video-section video{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}