@import url("https://use.typekit.net/bgr1qrv.css");
.container{
    background: #1C1B1B;
    /* padding-top: 70px; */
    overflow-x: hidden;
}
.mydata-info{
    color: #FF9900;
    font-weight: 600;
    width: 100%;    
    text-align: center;
    display: flex;
    height: 45vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    padding: 0 2%;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 50px;
    row-gap: 40px;
    min-height: 32vh;
}

.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}

.card .video_no {
    position: absolute;
    top: 0%;
    left: 0%;
    padding: 6px 14px;
    background-color: #FF9900;
    color: #fff;
    font-family: "proxima-nova";
    font-size: 12px;
    z-index: 2;
}
.card .duration {
    position: absolute;
    bottom: 7%;
    right: 3%;
    padding: 5px 10px;
    background-color: #fff3;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    z-index: 2;
    text-shadow: 2px 1px 5px #000;
}

.card-image{
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.card-image img{
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.card h4 {
    color: #FFFFFF;
    font-family: "proxima-nova-condensed";
    font-weight: 800;
    font-size: 15px;
}
.card p {
    color: #A5A5A5;
    font-family: "proxima-nova";
    font-size: 12px;
    position: relative;
    top: -1%;
}
.card .readmoreless {
    color: #00CBFF;
    font-family: 'proxima-nova';
    font-weight: 800;
    cursor: pointer;
}
.card-info{
      height: 80px;
}
.buttons{
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-around;
    gap: 2%;       
}
.buy-btn svg{
    height: 50%;
}
/* .buttons button{
    border-radius: 4px;
} */
.buttons .buy-btn{
    width: 100%;
    height: 3vw;
    font-size: 15px;
    color: #FFFFFF;
    background-color: #F8981D;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    font-family: 'proxima-nova';
}
.play-btn{
    width: 100%;
    height: 3vw;
    border: none;
    cursor: pointer;
    background-color: #F8981D;
    color: #fff;
    font-size: 18px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.btn-1{
    width: 49%;
    height: 3vw;
    font-size: 18px;
    font-family: 'Proxima Nova Bl';
    color: #FFFFFF;
    background-color: #FF9900;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}
.btn-2 {
    width: 49%;
    height: 3vw;
    font-size: 18px;
    font-family: '  pins', sans-serif;
    color: #FFFFFF;
    background-color: #575757;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}
.buttons img {
    background: transparent;
    width: 4.5vw;
}
button h1 {
    text-align: center;
    font-size: 16px;
    transform: translateY(2px);
    background: transparent;
    color: #FFFFFF;
    font-family: 'proxima-nova';
}
.card i {
    color: #ffffff;
    position: absolute;

    background: transparent;
    font-size: 50px;
    cursor: pointer;
}

.video-overlay{
    width: 100%;
    height: 200px;
    object-fit: cover;
    position: absolute;
    z-index: 1;
}

.pagination {
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding: 20px;
}
.pagination-button {
    display: block;
    width: 3.5vw;
    height: 3.5vw;
    font-family: 'proxima-nova';
    background-color: #fff;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    margin: 3px;
    font-weight: 700;
    background: #272727;
    transition: all 0.3s ease;
    border: none;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination-button:first-child {
    width: 7vw;
    height: 3.5vw;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
  .pagination-button:last-child {
    width: 7vw;
    height: 3.5vw;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-button.active{
    background-color: #FF9900;
  }
 
@media screen and (min-width:1027px) and (max-width:1200px){
        .card-container{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            width: 100%; 
            padding: 0 1%;
            gap: 10px;
            padding-top: 100px;
        }
     
    }


 @media screen and (min-width:768px) and (max-width:1027px){
    .card-container {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        width: 100%;
        padding: 0 1%;
        gap: 10px;
        padding-top: 40px;
    }
    .btn-1, 
    .btn-2 {
        width: 49%;
        height: 4.2vw;
        font-size: 18px;
        border: none;
    }
    .buttons .buy-btn{
        height: 4.2vw;
    }
    .buttons img {
        background: transparent;
        width: 7vw;
    }
 }

 @media screen and (min-width:426px) and (max-width:768px){

    .container {
        background: #1C1B1B;
    }

    .card-container {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        width: 100%;
        padding: 0 1%;
        gap: 10px;
        padding-top: 40px;
        position: relative;
        top:18vh;
    }
    .btn-1, .btn-2 {
        width: 49%;
        height: 6vw;
        font-size: 18px;
        border: none;
    }
    .buttons .buy-btn{
        height: 6vw;
    }
    .buttons img {
        background: transparent;
        width: 10vw;
    }
    .pagination {
        position: relative;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 90px;
    }
    .pagination-button {
        display: block;
        width: 7vw;
        height: 7vw;
        background-color: #fff;
        text-align: center;
        text-decoration: none;
        color: #ffffff;
        margin: 3px;
        background: #272727;
        transition: all 0.3s ease;
        border: none;
        font-size: 17px;
    }
    .pagination-button:first-child {
        width: 15vw;
        height: 7vw;
        margin-right: 5px;
    }
      
      .pagination-button:last-child {
        width: 15vw;
        height: 7vw;
        margin-left: 5px;
      }
      

 }

 @media screen and (max-width:426px){
    .container {
        background: #1C1B1B;
        overflow-x: inherit;
    }
    .card-container {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        width: 100%;
        padding: 0 1%;
        gap: 20px;
        padding-top: 40px;
        top:22vh;
        position: relative;
    }
    .card-image {
        width: 100%;
        height: 200px;
    }
    .card-video video {
        width: 90%;
        position: absolute;
        z-index: 2;
    }
    .btn-1, .btn-2 {
        width: 49%;
        height: 11vw;
        font-size: 18px;
        border: none;
    }
    .buttons .buy-btn{
        height: 11vw;
    }
    .buttons img {
        background: transparent;
        width: auto;
    }
    .pagination {
        position: relative;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
    }
    .pagination-button {
        display: block;
        width: 9vw;
        height: 10vw;
        background-color: #fff;
        text-align: center;
        text-decoration: none;
        color: #ffffff;
        margin: 3px;
        background: #272727;
        transition: all 0.3s ease;
        border: none;
        font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pagination-button:first-child {
        width: 20vw;
        height: 10vw;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
      
      .pagination-button:last-child {
        width: 20vw;
        height: 10vw;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
 }

 @media screen and (max-width:950px) {
    .buttons .buy-btn{
        width: 100%;
        /* height: 40px; */
        font-size: 13px;
        /* font-family: 'Proxima Nova Bl'; */
        color: #FFFFFF;
        background-color: #FF9900;
        border: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
    }
    .play-btn{
        height: 11vw;

    }
 }









 /* ------------------------------- */
 
 .header-navmenu{
    width: 100%;
    display: flex;
    grid-column: 1/4;
    justify-content: center;
    align-items: center;
    /* overflow-x: auto; */
}
 .header-navmenu::-webkit-scrollbar {
    height: 0;
  }

 .header-navmenu ul{
    display: flex;
    gap: 7.5vw;
    list-style: none;
}
 .header-navmenu li a {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-family: "proxima-nova";
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

 .header-navmenu li  input{
position: absolute;
width: 100%;
justify-content: center;
height: 100%;
display: flex;
cursor: pointer;
opacity: 0;
}

 .header-navmenu li  input:checked ~b{
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #FF9900;
    bottom: 0;
}



 .header-navmenu li{
    text-decoration: none;
    color: #fff;
    position: relative;
    padding: 10px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "proxima-nova";
    width: max-content;
    cursor: pointer;
    position: relative;
    }

    .header-navmenu li:hover p{
        display: flex;
    }

    .header-navmenu li p{
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        top: 00%;
        background-color: #1C1B1B;
        border: 1px solid #fff;
        display: none;
        z-index: 1;
    }

   .header-navmenu li span{
        background: red;
        padding: 2px 5px;
    }
  .header-navmenu li .dropdown-content {
        position: absolute;
        top: 100%;
        background-color: #000;
        padding: 5px 10px;
        min-width: 100%;
    }
    .dropdown-content a{
        font-size: 12px;
        padding: 10px 0px;
        width: 100%;
        display: flex;
    }

    .hamburger-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px; 
        height: 20px; 
        cursor: pointer;
        z-index: 9999;
      }
      
    .bar {
        width: 100%;
        height: 3px;
        background-color: #F8981D;
        transition: transform 0.3s, opacity 0.3s;
      }

      
      
     .bar.active:nth-child(1) {
        transform: translateY(11px) rotate(45deg);
    }
      
   .bar.active:nth-child(2) {
        opacity: 0;
      }
      
   .bar.active:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
      }

 .header-navmenu li.active::after{
content: "";
width: 100%;
position: absolute;
bottom: 0;
left: 0;
height: 2px;
background-color: #F8981D;
    }